import React from "react";
import styled from "styled-components";
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiFillYoutube,
} from "react-icons/ai";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  color: black;
  a {
    color: inherit;
  }
`;
const Kontakt = () => {
  return (
    <Wrapper>
      <AiFillFacebook />
      <AiOutlineInstagram />
      <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank">
        <AiFillYoutube />
      </a>
    </Wrapper>
  );
};

export default Kontakt;
