import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";

const Container = styled.div`
  position: relative;
  text-align: center;
`;
const TextWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* padding: 5px; */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  overflow: hidden;
  font-size: 2rem;
  color: white;
  line-height: 2rem;
  font-style: italic;
  & :first-child {
    font-weight: bold;
    font-size: 2.5rem;
  }
`;
const Background = styled(Img)`
  filter: brightness(${({ dim }) => dim && 0.6});
  transition: filter 2s ease;
  z-index: -1;
`;

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      klient: file(relativePath: { eq: "zadowolony_klient.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [containerRef, containerInView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  return (
    <Container ref={containerRef}>
      <Background
        fluid={data.klient.childImageSharp.fluid}
        dim={containerInView}
      />
      <TextWrapper>Samułel to prawilna morda polecam - Mati</TextWrapper>
    </Container>
  );
};

export default Image;
