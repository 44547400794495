import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
// import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { motion, useCycle, AnimatePresence } from "framer-motion";

const Container = styled.div`
  height: 100vh;
  position: relative;
`;
const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  transition: filter 0.5s ease;
  /* ::before,
  ::after { */
  animation: dim 1s ease 1s forwards;
  /* } */

  @keyframes dim {
    to {
      filter: brightness(0.6);
    }
  }
`;
const TextWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 2.3rem;
  font-weight: bold;
  color: white;
`;
const variants = {
  start: {
    opacity: 0,
    y: 100,
    x: 150,
  },
  end: firstRender => ({
    opacity: 1,
    y: 100,
    x: 0,
    transition: {
      delay: firstRender ? 1.5 : 0.5,
    },
  }),
  exit: {
    x: -150,
    opacity: 0,
  },
};
const Hero = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [text, cycleText] = useCycle(
    "Samuel Podolak",
    "Barber",
    "Fiozof",
    "Zjarus",
    "Człowiek renesansu"
  );
  const [imageLoaded, setImageLoaded] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  useEffect(() => {
    if (!imageLoaded) return;
    let intervalId = null;
    setTimeout(() => {
      setIsFirstRender(false);
      intervalId = setInterval(() => {
        cycleText();
      }, 3000);
      return () => clearInterval(intervalId);
    }, 2000);
    // eslint-disable-next-line
  }, [imageLoaded]);

  return (
    <Container>
      <StyledImg
        fluid={data.placeholderImage.childImageSharp.fluid}
        alt={"Samuel Podolak"}
        onLoad={() => setImageLoaded(true)}
      />
      <TextWrapper>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            variants={variants}
            initial="start"
            animate="end"
            exit="exit"
            custom={isFirstRender}
            key={text}
          >
            {text}
          </motion.div>
        </AnimatePresence>
      </TextWrapper>
    </Container>
  );
};

export default Hero;
