import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { TiArrowBack } from "react-icons/ti";

const Container = styled.div`
  position: relative;
  text-align: center;
`;
const TextWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* padding: 5px; */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  font-size: 2rem;
  color: white;
  line-height: 2rem;
  font-style: italic;
  & :first-child {
    font-weight: bold;
    font-size: 2.5rem;
  }
`;
const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      przed: file(relativePath: { eq: "przed.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      po: file(relativePath: { eq: "po.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [arrowRef, arrowInView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  return (
    <Container>
      <Img
        fluid={data.przed.childImageSharp.fluid}
        style={{ filter: "brightness(0.8)" }}
      />
      <Img
        fluid={data.po.childImageSharp.fluid}
        style={{ filter: "brightness(0.8)" }}
      />
      <TextWrapper>
        <div style={{ display: "flex" }}>
          <motion.span
            initial={{
              fontSize: "6rem",
              color: "yellow",
              scaleX: -1,
              scale: 0,
            }}
            animate={arrowInView ? { rotate: 260, scale: 1 } : {}}
            transition={{ duration: 0.8 }}
            ref={arrowRef}
          >
            <TiArrowBack />
          </motion.span>
        </div>
      </TextWrapper>
    </Container>
  );
};

export default Image;
