import React from "react";
import SEO from "../components/seo";
import Hero from "../components/Hero";
import Bio from "../components/Bio";
import ZadowoleniKlienci from "../components/ZadowoleniKlienci";
import EfektWow from "../components/EfektWow";
import Kontakt from "../components/Kontakt";
import "../utils/fonts/fonts.css";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,body{
  margin:0;
  padding:0;
  *,*::before,*::after{
    box-sizing:border-box;
  }
  font-family: Raleway;

}
`;

const IndexPage = () => (
  <>
    <SEO title="Samuel Podolak - Barber XXI wieku" />
    <GlobalStyle />
    <Hero />
    <Bio />
    <ZadowoleniKlienci />
    <EfektWow />
    <Kontakt />
  </>
);

export default IndexPage;
