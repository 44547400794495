import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Container = styled.div`
  position: relative;
  text-align: center;
`;
const TextWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  overflow: hidden;
  font-size: 2rem;
  color: white;
  line-height: 2rem;
  font-style: italic;
  & :first-child {
    font-weight: bold;
    font-size: 2.5rem;
  }
`;
const Background = styled(Img)`
  filter: brightness(${({ dim }) => dim && 0.6});
  transition: filter 2s ease;
  z-index: -1;
`;
const variants = {
  invisible: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 2 },
  },
};
const SecondaryImgWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  div {
    font-size: 1rem;
  }
`;

const SecondaryImg = styled(Img)`
  border-radius: 50%;
  overflow: hidden;
  width: 150px;
  height: 150px;
`;
const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "patusy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      secondary: file(relativePath: { eq: "secondary.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [titleRef, titleInView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });
  const [bioTextRef, bioTextInView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const [secondaryRef, secondaryInView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });
  return (
    <Container>
      <Background
        fluid={data.background.childImageSharp.fluid}
        dim={bioTextInView}
      />
      <TextWrapper>
        <motion.div
          variants={variants}
          initial="invisible"
          animate={titleInView ? "visible" : ""}
          ref={titleRef}
        >
          Biografia
        </motion.div>
        <motion.div
          variants={variants}
          initial="invisible"
          animate={bioTextInView ? "visible" : ""}
          ref={bioTextRef}
        >
          Ogolnie to zaczelo sie tak ze najebany z ziomkiem sie na zero
          ojebalismy i dalej to juz tak samo od wlosa do wlosa poszlo
        </motion.div>
        <SecondaryImgWrapper
          ref={secondaryRef}
          variants={variants}
          initial="invisible"
          animate={secondaryInView ? "visible" : ""}
        >
          <SecondaryImg fluid={data.secondary.childImageSharp.fluid} />
          <div>Lubie se czasem kogos opierdolic na elegancko</div>
        </SecondaryImgWrapper>
      </TextWrapper>
    </Container>
  );
};

export default Image;
